export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"application-name","content":"InterYachts"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"keywords","content":"yachting, yachts de luxe, vente de bateaux, gestion de yachts, location de yachts, Monaco"},{"name":"robots","content":"index, follow"},{"name":"language","content":"fr"},{"http-equiv":"Cache-Control","content":"no-cache, no-store, must-revalidate"},{"http-equiv":"Expires","content":"0"},{"http-equiv":"X-UA-Compatible","content":"IE=edge"},{"property":"og:title","content":"Interyachts : Leader des Services Nautiques"},{"property":"description","content":"Fondée en 1999 par Gianfranco Rossi et dirigée par Nicolas Braud, Interyachts propose des services complets de yachting, incluant la vente, la location et la gestion de yachts de luxe."},{"property":"og:description","content":"Fondée en 1999 par Gianfranco Rossi et dirigée par Nicolas Braud, Interyachts propose des services complets de yachting, incluant la vente, la location et la gestion de yachts de luxe."},{"property":"og:type","content":"website"},{"property":"og:url","content":"https://interyachts.mc"},{"property":"og:image","content":"https://interyachts.mc/files/bda24b16-b096-48f8-9622-295afa623d6b.png"},{"property":"og:locale","content":"fr_FR"},{"property":"og:site_name","content":"Interyachts"}],"link":[],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"fr"},"title":"INTERYACHTS","charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000